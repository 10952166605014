/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function ($) {


    function setPushyHeight() {
        var headerHeight = $("header.main_header").innerHeight();
        var pushyTop = headerHeight;
        var width = window.innerWidth;

        $(".pushy").css('top', pushyTop);

        if (width < 991) {
            $("body").css('padding-top', pushyTop);
        }
    }

    function em_add_markers_to_map( markers, map ) {
        console.log('test123');
        for( var i in markers ) {
            var markerJSONobj = markers[ i ];
            new google.maps.Marker({
                position: new google.maps.LatLng( markerJSONobj.latitude, markerJSONobj.longitude ),
                map: map,
                title: markerJSONobj.title,
                icon: markerJSONobj.image
            });
        }

    }


    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                /*
                 * Replace all SVG images with inline SVG
                 */
                $('img.svg').each(function () {
                    var $img = jQuery(this);
                    var imgID = $img.attr('id');
                    var imgClass = $img.attr('class');
                    var imgURL = $img.attr('src');

                    $.get(imgURL, function (data) {
                        // Get the SVG tag, ignore the rest
                        var $svg = $(data).find('svg');

                        // Add replaced image's ID to the new SVG
                        if (typeof imgID !== 'undefined') {
                            $svg = $svg.attr('id', imgID);
                        }
                        // Add replaced image's classes to the new SVG
                        if (typeof imgClass !== 'undefined') {
                            $svg = $svg.attr('class', imgClass + ' replaced-svg');
                        }

                        // Remove any invalid XML tags as per http://validator.w3.org
                        $svg = $svg.removeAttr('xmlns:a');

                        // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
                        if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                            $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                        }

                        // Replace image with new SVG
                        $img.replaceWith($svg);

                    }, 'xml');

                });

                var delay;

                $('.hamburger-menu').click(function () {
                    $('.bar').toggleClass('animate');
                    $(this).toggleClass('is-active');

                    if ($(this).hasClass('open') === true) {

                        delay = 0;
                        $('#mobileNav > li').each(function () {
                            $(this).delay(delay).animate({
                                opacity: 1,
                                marginTop: '-20px'
                            }, 100);

                            $(this).addClass('animate-it');

                            delay += 70;
                        });

                        $(this).removeClass('open');

                    } else {
                        $(this).addClass('open');

                        delay = 500;

                        $('#mobileNav > li').each(function () {
                            $(this).delay(delay).animate({
                                opacity: 1,
                                marginTop: '0px'
                            }, 200);

                            $(this).addClass('animate-it');

                            delay += 60;
                        });
                    }
                });

                var mobile_li_with_children = $('#mobileNav > li.menu-item-has-children');
                mobile_li_with_children.prepend('<span class="arrow"></span>');

                mobile_li_with_children.find('.arrow').on('click', function () {

                    if ($(this).parent().hasClass('open')) {
                        // do nothing G
                    } else {
                        // close other open submenu's
                        $('#mobileNav > li.menu-item-has-children.open > .sub-menu').slideToggle(200);
                        $('#mobileNav > li.menu-item-has-children.open').removeClass('open');
                    }

                    $(this).parent().toggleClass('open');
                    $(this).parent().find('.sub-menu').first().slideToggle(200);

                });

                var mobile_li_subs_with_children = $('#mobileNav .sub-menu > li.menu-item-has-children');
                mobile_li_subs_with_children.prepend('<span class="arrow"></span>');

                mobile_li_subs_with_children.find('.arrow').on('click', function () {

                    if ($(this).parent().hasClass('open')) {
                        // do nothing G
                    } else {
                        // close other open submenu's
                        $('#mobileNav .sub-menu > li.menu-item-has-children.open > .sub-menu').slideToggle(200);
                        $('#mobileNav .sub-menu > li.menu-item-has-children.open').removeClass('open');
                    }

                    $(this).parent().toggleClass('open');
                    $(this).parent().find('.sub-menu').slideToggle(200);

                });


                $(window).resize(function () {
                    setPushyHeight();
                });

                $('.scroll-icon').click(function () {
                    $("html, body").animate({ scrollTop: $(window).height()}, 600);
                    return false;
                });

                $('.mobile-language > a').click(function () {
                    $('.mobile-language .sub-menu').toggleClass('toggle');
                    return false;
                });


                $('.media-slider').slick({
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                    focusOnSelect: true,
                    arrows: false,
                    centerPadding: '100px',
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });

                $('.afstanden-slider__wrapper').slick({
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerMode: true,
                    focusOnSelect: true,
                    arrows: true,
                    centerPadding: '50px',
                    prevArrow: $('.prev'),
                    nextArrow: $('.next'),
                    slide: '.afstand-slide',
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });

                $(window).scroll(function() {
                    var top_of_element = $(".footer-logo").offset().top;
                    var bottom_of_element = $(".footer-logo").offset().top + $(".footer-logo").outerHeight();
                    var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                    var top_of_screen = $(window).scrollTop();

                    if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                        if($('.footer-logo video')[0].autoplay === false) {

                            $('.footer-logo video')[0].load();
                            $('.footer-logo video')[0].autoplay = true;
                        }
                    }
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                setInterval(function time() {
                    var d = new Date();
                    var hours = 24 - d.getHours();
                    var min = 60 - d.getMinutes();
                    if ((min + '').length === 1) {
                        min = '0' + min;
                    }
                    var sec = 60 - d.getSeconds();
                    if ((sec + '').length === 1) {
                        sec = '0' + sec;
                    }
                    $('.js-countdown').html('Nog ' + hours + ':' + min + ':' + sec + ' te gaan');
                }, 1000);

                $('.js-slider-big').slick({
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    arrows: false,
                    autoplaySpeed: 0,
                    speed: 5000,
                    cssEase: 'linear',
                    autoplay: true,
                    rtl: true,
                    infinite: true,
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });

                $('.js-slider-small').slick({
                    slidesToShow: 7,
                    slidesToScroll: 1,
                    arrows: false,
                    autoplaySpeed: 0,
                    speed: 4000,
                    cssEase: 'linear',
                    autoplay: true,
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });


            },
            finalize: function () {
            }
        },        // Home page
        'afstanden_template_default': {
            init: function () {
                var mod = $('.toggle-map');

                var window_height = $(window).height();
                var map_height = window_height - 24 - 80;


                console.log(window_height);
                console.log(map_height);


                $('#map-canvas').height(map_height);

                if (window.auto_trigger_map) {
                    $('.map-top .post-' + window.auto_trigger_map).trigger('click');
                }

                $('.toggle-map').click(function (e) {

                    e.preventDefault();

                    var $this = $(this);

                    if ($this.hasClass('toggle-map-active')) {
                        return false; //this map is already active,..stop execution immediately
                    }

                    $this.removeClass('toggle-map-active');
                    $this.addClass('toggle-map-active');

                    //clear old map, create a blank new one centered in Enske...
                    var map = window.initialize_new_google_map(); // this function is set in wp_footer() on HOME;
                    // add all the markers

                    console.log($this.data('markers'));

                    em_add_markers_to_map($this.data('markers'), map);

                    var kmlLayer = new google.maps.KmlLayer({
                        url: $this.data('kml'),
                        suppressInfoWindows: true,
                        map: map
                    });

                    return false;
                });


                $("document").ready(function() {
                    $(".toggle-map").trigger('click');
                });
            },
            finalize: function () {
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
